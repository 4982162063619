import React from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import Header from './header';
import Footer from './footer';
import { ROUTES, THEME } from '../utils/constants';
import '../css/font-face.css';

const ROUTES_ARR = ROUTES.reduce((acc, curr) => [...acc, curr.path], []);

const Wrapper = styled.div`
  width: 68rem;
  max-width: 90%;
  margin: 0 auto;
`;

const GlobalStyle = createGlobalStyle`
  :root {
    --reach-menu-button: 1;
  }

  input {
    width: 100%;
    @media (min-width: ${THEME.breakpoints[1]}) {
      width: 15rem;
    }
  }

  button:focus {
    outline:0;
  }

  footer:before {
    content: '';
    top: 0px;
    height: 1px;
    left: 0px;
    position: absolute;
    width: 100%;
    opacity: 0.1;
    background-color: black;
  }

  textarea {
    width: 100%;
  }

  input, textarea {
    padding: 3px 6px;
    border-radius: 2px;
    shadow: none;
    border: 1px solid grey;
  }

  h2 {
    line-height: 1.5rem;
  }

  li {
    margin-bottom: 0.5rem;
  }

  // HAMBURGER MENU

  [data-reach-menu] {
    display: block;
    position: absolute;
  }

  [data-reach-menu-list] {
    display: block;
    white-space: nowrap;
    border: solid 1px hsla(0, 0%, 0%, 0.25);
    border-radius: 2px;
    background: hsla(0, 100%, 100%, 0.99);
    outline: none;
  }

  [data-reach-menu-item] {
    display: block;
    border-radius: 1px;
  }

  [data-reach-menu-item] {
    cursor: pointer;
    display: block;
    color: inherit;
    font: inherit;
    text-decoration: initial;
    padding: 5px 20px;
  }

  [data-reach-menu-item][data-selected] {
    background: ${THEME.colors.blue};
    color: white;
    outline: none;
  }

  // TAB MENU, PRACTICE PAGE

  [data-reach-tab-panel] {
    outline: none;
  }

  [data-reach-tab-list] {
    display: flex;
  }

  [data-reach-tab] {
    position: relative;
    display: inline-block;
    border: none;
    width: 33.333333%;
    padding: 0.5rem;
    margin: 0;
    background: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  [data-reach-tab]:before,
  [data-reach-tab][data-selected]:before {
    content: '';
    position: absolute;
    width: 95%;
    margin: 0 auto;
    height: 2px;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${THEME.colors.lightGrey};
    border-radius: 5px;
    transition: 0.15s ease;
  }

  [data-reach-tab]:hover:before {
    background: ${THEME.colors.blue};
    transition: background-color 0.5s;
  }

  [data-reach-tab][data-selected]:before {
    background: ${THEME.colors.blue};
  }
`;

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query ThisQuery {
        featureStoryBadge: file(relativePath: { eq: "featureStoryBadge.png" }) {
          childImageSharp {
            fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        superLawyersBadge: file(relativePath: { eq: "superLawyersBadge.png" }) {
          childImageSharp {
            fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        risingStarBadge: file(relativePath: { eq: "risingStarBadge.png" }) {
          childImageSharp {
            fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        allContentfulPerson(filter: { name: { eq: "Carla Sanderson" } }) {
          edges {
            node {
              name
              company
              email
              phone
              facebook
              shortBio {
                shortBio
              }
            }
          }
        }
      }
    `}
  >
    {({
      featureStoryBadge,
      superLawyersBadge,
      risingStarBadge,
      allContentfulPerson: { edges },
    }) => (
      <>
        <Helmet
          title={`${
            ROUTES_ARR.includes(location.pathname)
              ? `${
                  ROUTES.find((route) => route.path === location.pathname).name
                } - `
              : ''
          }${edges[0].node.company} `}
          meta={[
            { name: 'description', content: edges[0].node.shortBio.shortBio },
            {
              name: 'keywords',
              content:
                'criminal defense, lawyer, new york city, brooklyn, manhattan, queens, law office, legal office',
            },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Wrapper>
          <GlobalStyle />
          <ThemeProvider theme={THEME}>
            <>
              <Header {...location} {...edges[0].node} />
              {children}
              <Footer
                {...edges[0].node}
                featureStoryBadge={featureStoryBadge.childImageSharp.fluid}
                superLawyersBadge={superLawyersBadge.childImageSharp.fluid}
                risingStarBadge={risingStarBadge.childImageSharp.fluid}
              />
            </>
          </ThemeProvider>
        </Wrapper>
      </>
    )}
  </StaticQuery>
);

export default Layout;
