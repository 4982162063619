import React from 'react';
import Responsive from 'react-responsive';
import styled from 'styled-components';
import { THEME } from '../utils/constants';

export const A = styled.a`
  transition: color 1s;
  text-decoration: none;
  color: ${({ color }) => color || THEME.colors.darkGrey};
  & :hover {
    color: black;
  }
`;

export const Desktop = (props) => (
  <Responsive {...props} minWidth={THEME.breakpoints[1]} />
);
export const Mobile = (props) => (
  <Responsive {...props} maxWidth={THEME.breakpoints[1]} />
);
