import React from 'react';
import styled from 'styled-components';
import { withPrefix, Link as GatsbyLink } from 'gatsby';
import { Flex } from 'rebass/styled-components';
import feather from 'feather-icons';
import { Menu, MenuList, MenuButton, MenuLink } from '@reach/menu-button';
import { ROUTES } from '../utils/constants';

const DesktopMenu = styled(Flex)`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    display: flex;
  }
`;

const Link = styled(GatsbyLink)`
  position: relative;
  transition: color 1s;
  height: min-content;
  text-decoration: none;

  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  color: ${({ active }) => (active ? 'black' : '#595959')};

  :hover {
    color: #000;
  }

  :before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: 0;
    background: ${({ active, theme }) =>
      active ? theme.colors.blue : theme.colors.lightblue};
    visibility: ${({ underline, hoverEffect }) =>
      underline && hoverEffect ? 'visible' : 'hidden'};
    transform: ${({ underline, hoverEffect }) =>
      underline && hoverEffect ? 'scaleX(1)' : 'scaleX(0)'};
    border-radius: 5px;
    transition: 0.15s ease;
  }

  & :hover:before {
    visibility: ${({ hoverEffect }) => (hoverEffect ? 'visible' : 'invisible')};
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    background: ${({ active, theme }) =>
      active ? theme.colors.blue : theme.colors.lightblue};
  }
`;

const H4 = styled.h4`
  font-size: 1.2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    font-size: 1.5rem;
  }
`;

const WrappedMenuBtn = styled(MenuButton)`
  background: none;
  border: none;
  height: 2rem;
  margin-top: -5px;
  padding: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    display: none;
  }
`;

const MobileMenu = () => (
  <Menu>
    <WrappedMenuBtn>
      <div
        style={{
          width: '25px',
          height: '20px',
          backgroundImage: `url('data:image/svg+xml;utf8,${feather.icons[
            'menu'
          ].toSvg({
            'stroke-width': 3,
            color: 'black',
            height: '20',
          })}`,
        }}
      />
    </WrappedMenuBtn>
    <MenuList>
      {ROUTES.map(({ path, name }, idx) => (
        <MenuLink as={GatsbyLink} to={path} key={idx}>
          {name}
        </MenuLink>
      ))}
    </MenuList>
  </Menu>
);

const Header = ({ pathname, company, theme }) => (
  <header>
    <Flex
      justifyContent="space-between"
      mb={['3rem', null, '6rem']}
      pt={['2rem', null, '4.5rem']}
    >
      <Flex width={[3 / 4, 1 / 2]} justifyContent="space-between">
        <Link to="/" active underline={false}>
          <H4>{company}</H4>
        </Link>
      </Flex>

      <DesktopMenu width={1 / 2} ml={['3rem']} justifyContent="space-between">
        {ROUTES.map(({ path, name }, idx) => (
          <Link
            to={path}
            key={idx}
            hoverEffect
            active={pathname.includes(withPrefix(`/${path}`))}
            underline={pathname.includes(withPrefix(`/${path}`))}
          >
            {name}
          </Link>
        ))}
      </DesktopMenu>

      <Flex justifyContent="space-between">
        <MobileMenu />
      </Flex>
    </Flex>
  </header>
);

export default Header;
