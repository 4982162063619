export const ROUTES = [
  {
    path: '/practice',
    name: 'The Practice',
  },
  {
    path: '/notable-cases',
    name: 'Notable Cases',
  },
  {
    path: '/about',
    name: 'About',
  },
  {
    path: '/contact',
    name: 'Contact',
  },
];

const blue = 'rgba(54, 114, 255, 1)';
const lightblue = 'rgba(158, 187, 255, 1)';
const green = 'rgba(36, 180, 126, 1)';
const gold = 'rgba(234, 162, 62, 1)';
const darkGrey = 'hsla(0,0%,0%,0.8)';
const lightGrey = '#E5E5E5';

const facebook = 'rgba(60, 90, 153, 1)';
const linkedin = 'rgba(0, 119, 181, 1)';

export const THEME = {
  breakpoints: ['40em', '52em', '64em'],
  buttons: {
    primary: {
      color: '#fff',
      backgroundColor: blue,
    },
  },
  fonts: {
    body: 'Feijoa, sans-serif',
    heading: 'Untitled Sans, sans-serif',
  },
  colors: {
    blue,
    lightblue,
    green,
    gold,
    darkGrey,
    lightGrey,
    facebook,
    linkedin,
  },
};
