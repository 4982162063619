import React from 'react';
import feather from 'feather-icons';
import { Flex, Text, Box } from 'rebass/styled-components';
import { Desktop, Mobile, A } from '../utils/styles';
import { THEME } from '../utils/constants';

const Facebook = ({ name }) => (
  <A
    href={`https://facebook.com/${name}`}
    rel="noopener noreferrer"
    target="_blank"
  >
    <div
      style={{
        display: 'flex',
        width: '25px',
        height: '20px',
        backgroundImage: `url('data:image/svg+xml;utf8,${feather.icons[
          'facebook'
        ].toSvg({
          'stroke-width': 2,
          color: THEME.colors.facebook,
          height: '20',
        })}`,
      }}
    />
  </A>
);
const Avvo = () => (
  <A
    href={`https://www.avvo.com/attorneys/10016-ny-carla-sanderson-3797581.html#client_reviews`}
    rel="noopener noreferrer"
    target="_blank"
  >
    <div
      style={{
        display: 'inline-block',
        width: '24px',
        marginBottom: '-3px',
        height: '18px',
        backgroundImage: `url('data:image/svg+xml;utf8,${feather.icons[
          'link'
        ].toSvg({
          'stroke-width': 2,
          color: THEME.colors.darkGrey,
          height: '18',
        })}`,
      }}
    />
    Avvo.com
  </A>
);
const LinkedIn = () => (
  <A
    href={`https://www.linkedin.com/in/carla-sanderson-0883782/`}
    rel="noopener noreferrer"
    target="_blank"
  >
    <div
      style={{
        display: 'flex',
        width: '25px',
        height: '20px',
        backgroundImage: `url('data:image/svg+xml;utf8,${feather.icons[
          'linkedin'
        ].toSvg({
          'stroke-width': 2,
          color: THEME.colors.linkedin,
          height: '20',
        })}`,
      }}
    />
  </A>
);
const Email = ({ email }) => (
  <A href={`mailto:${email}`}>
    <div
      style={{
        display: 'flex',
        width: '25px',
        height: '20px',
        backgroundImage: `url('data:image/svg+xml;utf8,${feather.icons[
          'mail'
        ].toSvg({
          'stroke-width': 2,
          color: THEME.colors.darkGrey,
          height: '20',
        })}`,
      }}
    />
  </A>
);

const Footer = ({
  company,
  email,
  facebook,
  phone,
  featureStoryBadge,
  superLawyersBadge,
  risingStarBadge,
}) => (
  <footer style={{ position: 'relative', marginTop: '3rem' }}>
    <Box pt="4" mb={[5]}>
      <Flex justifyContent="space-between">
        <Desktop>
          <Flex width={1 / 4} flexDirection="column">
            {company} © {new Date().getFullYear()}
            <Text fontSize={[1]} mt={1}>
              Attorney Advertising: Prior results do not guarantee a similar
              outcome.
            </Text>
          </Flex>
          <Flex width={1 / 4}> </Flex>
          <Flex
            mr={[4]}
            style={{ gap: '1rem' }}
            flexDirection="row"
            alignItems="flex-end"
          >
            <a
              href="https://digital.superlawyers.com/superlawyers/nysl23/MobilePagedReplica.action?pm=2&folio=36#pg36"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={featureStoryBadge.src}
                alt="Carla M. Sanderson, Super Lawyers Feature Story"
              />
            </a>
            <a
              href="https://digital.superlawyers.com/superlawyers/nysl23/MobilePagedReplica.action?pm=2&folio=36#pg36"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={risingStarBadge.src}
                alt="Carla M. Sanderson, Super Lawyers Rising Star"
              />
            </a>
            <a
              href="https://profiles.superlawyers.com/new-york-metro/new-york/lawyer/carla-m-sanderson/05ac5be2-72c3-467e-9114-fd976144659f.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={superLawyersBadge.src}
                alt="Carla M. Sanderson, 2023 Super Lawyer"
              />
            </a>
          </Flex>
          <Flex width={1 / 4} flexDirection="column">
            <Text>260 Madison Ave, 22nd Fl.</Text>
            <Text mb="3">New York, NY 10016</Text>

            <Box>
              <A href={`tel:${phone}`}>{phone}</A>
            </Box>
            <Box mb="1">
              <Avvo />
            </Box>
            <Flex width={1 / 3} mb="3">
              <Flex mr={'1rem'} flexDirection="column">
                <Email email={email} />
              </Flex>
              <Flex mr={'0.8rem'} flexDirection="column">
                <LinkedIn />
              </Flex>
              <Flex flexDirection="column">
                <Facebook name={facebook} />
              </Flex>
            </Flex>
          </Flex>
        </Desktop>

        <Mobile>
          <Flex flexDirection="column">
            <Flex mt={[1]}>
              {company} © {new Date().getFullYear()}
            </Flex>
            <Flex>260 Madison Ave, 22nd Floor</Flex>
            <Flex mb="3">New York, NY 10016</Flex>

            <Flex mb="1">
              <A href={`tel:${phone}`}>{phone}</A>
            </Flex>
            <Box mb="1">
              <Avvo />
            </Box>
            <Flex
              mt={[3]}
              style={{ gap: '1rem' }}
              flexDirection="row"
              alignItems="flex-end"
            >
              <a
                href="https://digital.superlawyers.com/superlawyers/nysl23/MobilePagedReplica.action?pm=2&folio=36#pg36"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={featureStoryBadge.src}
                  alt="Carla M. Sanderson, Super Lawyers Feature Story"
                />
              </a>
              <a
                href="https://digital.superlawyers.com/superlawyers/nysl23/MobilePagedReplica.action?pm=2&folio=36#pg36"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={risingStarBadge.src}
                  alt="Carla M. Sanderson, Super Lawyers Rising Star"
                />
              </a>
              <a
                href="https://profiles.superlawyers.com/new-york-metro/new-york/lawyer/carla-m-sanderson/05ac5be2-72c3-467e-9114-fd976144659f.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={superLawyersBadge.src}
                  alt="Carla M. Sanderson, 2023 Super Lawyer"
                />
              </a>
            </Flex>
            <Flex width={1 / 3}>
              <Flex mr={3} flexDirection="column">
                <Email email={email} />
              </Flex>
              <Flex mr={2} flexDirection="column">
                <LinkedIn />
              </Flex>
              <Flex flexDirection="column" mb="3">
                <Facebook name={facebook} />
              </Flex>
            </Flex>

            <Text fontSize={[1]} mt={1}>
              Attorney Advertising: Prior results do not guarantee a similar
              outcome.
            </Text>
          </Flex>
        </Mobile>
      </Flex>
    </Box>
  </footer>
);

export default Footer;
